<template>
  <b-modal
    id="convert-snapshot-to-model-modal"
    title="Confirm Snapshot Conversion"
  >
    <div class="p-1">
      <p>
        <span class="text-warning font-weight-bold">Warning</span>:
        Converting this snapshot into a model is a permanent action.
      </p>
      <p>
        Are you sure you wish to convert this snapshot into a model?
      </p>
      <p class="text-danger font-weight-bold">
        This operation may take a long time, please be patient.
        The snapshot will not remain after the conversion.
      </p>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        :disabled="loading"
        @click="cancel"
      >
        No, leave it
      </b-button>

      <b-button
        variant="danger"
        :disabled="loading"
        @click="convertSnapshot"
      >
        Convert snapshot
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ConvertSnapshotToModelModal',
  setup(props, context) {
    const loading = ref(false)
    const snapshotId = router.currentRoute.params.modelId

    const convertSnapshot = () => {
      loading.value = true
      coreService
        .post(`/v1/snapshots/${snapshotId}/convert`)
        .then(({ data }) => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: data.status,
              icon: 'LoaderIcon',
              variant: 'success',
            },
          })

          // Store the task_id in the task list to watch progress
          store.commit('workerTasks/ADD_TASK_TO_LIST', data.task_id)

          // Close the model
          store.dispatch('model/closeWorkspace')
          router.push({ name: 'model_browse' })
        })
        .catch(e => {
          // Handle errors
          const errorToast = {
            title: 'Import error',
            message: 'An unknown error occurred when trying to initiate the model snapshot import operation.',
          }

          if (e.response.data.message) {
            // Pass on the error message to the user if one is provided by the API
            errorToast.message = e.response.data.message
          }

          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: errorToast.title,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: errorToast.message,
            },
          })
        })
        .finally(() => {
          loading.value = false
          context.root.$bvModal.hide('convert-snapshot-to-model-modal')
        })
    }

    return {
      loading,
      convertSnapshot,
    }
  },
}
</script>
