<template>
  <b-modal
    id="delete-temp-model-modal"
    title="Delete Model Snapshot"
  >
    <div v-if="loading" class="text-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Loading..." />
    </div>
    <div v-else class="p-1">
      <p class="mb-50">Are you sure you wish to delete this model snapshot?</p>
      <p class="text-danger font-weight-bold">This action cannot be undone.</p>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        :disabled="loading"
        @click="cancel"
      >
        No, keep it
      </b-button>

      <b-button
        variant="danger"
        :disabled="loading"
        @click="deleteSnapshot"
      >
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import coreService from '@/libs/api-services/core-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DeleteTempModelModal',
  data: () => ({
    loading: false,
  }),
  methods: {
    deleteSnapshot() {
      this.loading = true
      const { modelId } = router.currentRoute.params
      coreService
        .delete(`/v1/model/${modelId}/temp_model`)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Model Snapshot Deleted',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: `An error occurred while deleting the model snapshot. ${e}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
          this.$bvModal.hide('delete-temp-model-modal')
          this.$store
            .dispatch('model/closeWorkspace')
            .then(() => { router.push({ name: 'model_browse' }) })
        })
    },
  },
}
</script>
